import React from 'react'
import Layout from '../components/layout'
import Bio from '../components/bio'
import { graphql } from 'gatsby'
import "./index.css"

const IndexPage = ({ data }) => {
  let myBio = data.allBioJson.edges[0].node
  return (
    <Layout>
      <Bio {...myBio} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allBioJson {
      edges {
        node {
          name
          role
          skills
          quote
          sub_quote
          description
        }
      }
    }
  }
`
