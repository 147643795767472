import React from 'react'
import './bio.css'
import bio_image from './profile_pic.jpg'
class Bio extends React.Component {
  render() {
    let { name, role, skills, quote, sub_quote, description } = this.props

    return (
      <div className="bio">
        <div className="bio-header">
          <img alt="" className="profile-photo" src={bio_image} />
          <div className="info">
            <h1 className="intro">{name}</h1>
            <p className="msg">{role}</p>
          </div>
          <h4 className="heading"> Skills</h4>
          <ul className="skills">
            {skills.map((skill, index) => (
              <li key={index}> {skill} </li>
            ))}
          </ul>
        </div>
        <div className="bio-body">
          <h2 className="quote">{quote}</h2>
          <p className="sub-quote">{sub_quote}</p>
          {description.map((para, index) => (
            <p key={index}>{para}</p>
          ))}
        </div>
      </div>
    )
  }
}

export default Bio
